 


<template>
  <div style="padding-left: 10px">
    <vx-card class="mb-4">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <div class="w-full">
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
              <label
                for="question"
                class="text-lg font-medium mb-2 mt-4 sm:mt-0"
                >Question</label
              >

              <vs-textarea
                height="80px"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="question"
                v-model="question"
                class="w-full"
              />
              <span class="text-danger text-sm">{{
                errors.first("question")
              }}</span>
            </p>
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
              <label for="answer" class="text-lg font-medium mb-2 mt-4 sm:mt-0"
                >Answer</label
              >

              <vs-textarea
                height="150px"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="answer"
                v-model="answer"
                class="w-full"
              />
              <span class="text-danger text-sm">{{
                errors.first("answer")
              }}</span>
            </p>
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              vs-w="12"
            >
              <vs-row vs-justify="flex-start" class="mt-10">
                <vs-button color="success" v-if="tid" @click="updateDetails()"
                  >Save Changes</vs-button
                >

                <vs-button
                  color="success"
                  v-if="!tid"
                  class="ml-4"
                  @click="addNew()"
                  >Save</vs-button
                >
                <vs-button
                  color="primary"
                  type="border"
                  class="ml-4"
                  @click="backtolist()"
                  >Back To FAQs</vs-button
                >
              </vs-row>
            </vs-row>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";

export default {
  name: "FAQsTopic",

  data: () => ({
    question:"",
    answer:"",
    tid: "", //topic id
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() && this.question != "" && this.answer != ""
      );
    },
  },
  methods: {
    ...mapActions("admin", [
      "getFaqsById",
      "createFAQsTopic",
      "updateFAQsTopic",
    ]),
    getQAsDetail() {
      let self = this;
      this.getFaqsById(this.tid).then((res) => {
        this.question = res.data.data.question;
        this.answer = res.data.data.answer;
        this.tid = res.data.data._id;
      });
    },
    getDefaultData() {
      let self = this;
    },

    addNew() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            question: this.question,
            answer: this.answer,
          };

          this.createFAQsTopic(data).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "Added New Question",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push({
                name: "FAQs",
                params: { id: this.tid, sid: this.stid },
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },
    updateDetails() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            _id:this.tid,
            question: this.question,
            answer: this.answer
          };
          this.updateFAQsTopic(data).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "Updated",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push({
                name: "FAQs",
                params: { id: this.tid},
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },
    SubTopicList(id) {
      this.$router.push({ name: "SubTopicList", params: { id: id } });
    },
    backtolist() {
      this.$router.push({
        name: "FAQs",
        params: { id: this.tid, sid: this.stid },
      });
    },
  },

  created() {
    this.tid = this.$route.params.id;
    if (this.tid) {
      this.getQAsDetail();
    } else {
      this.getDefaultData();
    }
  },
};
</script>
  
<style>
#uploadBtn {
  display: none;
}
</style>
